<template>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-8">
        <select-empresa-caixa
          :comTodos="true"
          :noStyle="false"
          :empresa="empresaSelecionada.cod_empresa"
          @empresa="selectEmpresa"
          @caixa="selectCaixa"
        ></select-empresa-caixa>
      </div>
      <div class="col-4">
        <label>Data</label>
        <b-input
          type="date"
          class="form-control"
          v-model="data"
        ></b-input>
      </div>
    </div>
    <hr />
    <b-card>
      <div class="d-flex justify-content-end mb-2">
        <b-button
          variant="primary"
          @click="loadData"
        >
          <i class="fa fa-refresh"></i>
          Atualizar
        </b-button>
        <b-button
          variant="primary"
          @click="print"
          class="ml-2 mr-2"
        >
          <i class="fa fa-print"></i>
          Imprimir
        </b-button>
        <export-excel :data="relatorioExcel">
          <b-btn variant="info">
            <i class="fa fa-file-excel mr-1"></i> Exportar
          </b-btn>
        </export-excel>
      </div>
      <b-skeleton-table v-if="loading"></b-skeleton-table>
      <div
        id="printDiv"
        v-if="!loading"
      >
        <h3 class="text-center">Listagem de Caixa</h3>
        <h5 class="text-center">
          {{ empresaSelecionada.nome }} - {{ caixaSelecionado.nome }}
        </h5>
        <h5 class="text-center">{{ data | moment("DD/MM/YYYY") }}</h5>
        <hr />
        <b-table
          v-if="!loading"
          :items="lista"
          :fields="fields"
          hover
          striped
          select-mode="single"
          selectable
          @row-selected="openPedido"
          ref="tbLista"
        >
          <template #cell(data)="row">
            {{ row.value | moment("DD/MM/YYYY HH:mm") }}
          </template>
          <template #cell(total)="row">
            {{ row.value | currency }}
          </template>
          <template #cell(ident)="row">
            <div
              class="text-left"
              style="min-width: 200px"
            >
              <span v-if="row.value.includes('|')">
                {{ row.value.split("|")[0] }}
                <div v-if="row.value.split('|')[1].trim() != ''">
                  <small
                    ><i class="fa fa-user mr-1"></i
                    >{{ row.value.split("|")[1] }}</small
                  >
                </div>
              </span>
              <span v-if="!row.value.includes('|')">
                <span v-if="row.item.tipo == 'Pedido'">
                  <i class="fa fa-shopping-cart mr-1"></i>
                </span>
                <span v-if="row.item.tipo == 'Mesa'">
                  <i class="fa fa-barcode mr-1"></i>
                </span>
                {{ row.value }}
              </span>
            </div>
          </template>
        </b-table>

        <div class="row">
          <div class="col-12 text-right bg-light">
            <b-skeleton
              v-if="loading"
              width="50"
              style="width: 60px"
              height="30"
            ></b-skeleton>
            <div
              v-if="!loading"
              class="p-3"
            >
              <h4>
                <small><small class="mr-1">Total</small></small>
                <b>
                  {{
                    lista.reduce((acc, item) => acc + item.total, 0) | currency
                  }}
                </b>
              </h4>
            </div>
          </div>
          <div class="col-12 col-md-6 mt-2">
            <div v-if="loading">
              <b-skeleton height="200px"></b-skeleton>
            </div>
            <b-card v-if="!loading">
              <b-card-title
                >Por Forma de Pagamento
                <b-button
                  variant="primary"
                  @click="printFita"
                  class="ml-2 mr-2"
                >
                  <i class="fa fa-print"></i>
                  Imprimir Fita
                </b-button>
              </b-card-title>
              <b-card-text>
                <b-list-group>
                  <b-list-group-item
                    v-for="(value, key) in porForma"
                    :key="key"
                    class="d-flex justify-content-between"
                  >
                    <div>{{ key }}</div>
                    <div>
                      {{ value | currency }}
                    </div>
                  </b-list-group-item>
                  <b-list-group-item
                    class="d-flex justify-content-between bg-light"
                  >
                    <strong>Total</strong>
                    <div>
                      {{
                        lista.reduce((acc, item) => acc + item.total, 0)
                          | currency
                      }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-card-text>
            </b-card>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      id="modal-open-comanda"
      size="xl"
      hide-footer
      :modal-class="
        $store.state.isMobile ? `modal-full p-0 ` : 'modal-full p-0'
      "
      :body-class="`p-0`"
      :content-class="`p-0 ${$store.state.isMobile ? `fixed` : 'fixed'}`"
    >
      <mesa-new
        :mesa="mesaSelected"
        :blocked="true"
        :hideMesaMenu="true"
      ></mesa-new>
    </b-modal>
    <b-modal
      id="modal-open-pedido"
      size="xl"
      :content-class="'prodModal'"
      body-class="insideModal p-0 pt-2"
      hide-footer
      class="prodModal"
    >
      <Pedido :PedidoOpen="pedidoSelected"></Pedido>
    </b-modal>
  </div>
</template>

<script>
import SelectEmpresaCaixa from "../../components/common/SelectEmpresaCaixa.vue";
// import EmpresaCombo from '../../components/empresa/EmpresaCombo.vue'
import moment from "moment";
// import CaixaLib from "@/libs/Caixas";
import PrinterWindowHelper from "../../utils/PrinterWindowHelper";
// import Caixa from "@/libs/Caixas";
import EmpresasLib from "@/libs/EmpresasLib";
import MesaNew from "../mesas/MesaNew.vue";
import Mesalib from "@/libs/MesaLib";
import Pedido from "../../components/pedido/Pedido.vue";
import PedidoLib from "../../libs/Pedido";
import CaixaLib from "../../libs/Caixas";
export default {
  components: {
    //   EmpresaCombo,
    SelectEmpresaCaixa,
    MesaNew,
    Pedido,
  },
  props: {},
  mounted() {
    console.log("empresas", this.empresas);
    this.loadData();
  },
  data() {
    return {
      caixas: [],
      empresas: [
        this.$store.state.empresas,
        ...(this.$store.state.empresas.empresas
          ? this.$store.state.empresas.empresas
          : []),
      ],
      empresaSelecionada: this.$store.state.empresa_ativa,
      caixaSelecionado: this.$store.state.caixa_ativo,
      data: moment().format("YYYY-MM-DD"),
      lista: [],
      loading: true,
      fields: [
        { key: "data", label: "Data" },
        { key: "cod", label: "Código" },
        { key: "tipo", label: "Tipo" },
        { key: "ident", label: "Identificação" },
        { key: "pagamento", label: "Forma" },
        { key: "total", label: "Valor" },
      ],
      pedidoSelected: null,
      mesaSelected: null,
    };
  },
  watch: {
    empresaSelecionada() {
      this.caixaSelecionado = this.empresaSelecionada.caixas[0];
      this.loadData();
    },
    caixaSelecionado() {
      this.caixaSelecionado = this.empresaSelecionada.caixas.find(
        (x) => x.cod_caixa == this.caixaSelecionado.cod_caixa
      );

      this.loadData();
    },
    data() {
      this.loadData();
    },
  },
  computed: {
    relatorioExcel() {
      return this.lista.map((item) => {
        return {
          Data: moment(item.data).format("DD/MM/YYYY HH:mm"),
          Código: item.cod,
          Tipo: item.tipo,
          Identificação: item.ident,
          Forma: item.pagamento,
          Valor: item.total,
        };
      });
    },
    porForma() {
      return this.lista.reduce((acc, item) => {
        if (!acc[item.pagamento]) {
          acc[item.pagamento] = 0;
        }
        acc[item.pagamento] += item.total;
        return acc;
      }, {});
    },
  },
  methods: {
    async printFita() {
      // let fechamento = await CaixaLib.getAll({
      //   cod_empresa: this.empresaSelecionada.cod_empresa,
      //   cod_caixa: this.caixaSelecionado.cod_caixa,
      //   data: this.data,
      // });
      // console.log("fechamento", fechamento);
      await CaixaLib.printFita({
        // cod_fechamento: fechamento && fechamento.length>0?fechamento[0].cod_fechamento:0,
        cod_empresa: this.empresaSelecionada.cod_empresa,
        cod_caixa: this.caixaSelecionado ? this.caixaSelecionado.cod_caixa : 1,
        data: this.data,
        print: true,
      });
      this.$swal("Impressão solicitada!", "", "success");
    },
    async selectCaixa(cx) {
      console.log("cx", cx, this.empresaSelecionada, this.caixaSelecionado);
      // this.caixaSelecionado = this.caixas.filter((x) => x.cod_caixa == cx) || this.caixas[0];
      this.loadData();
    },
    async selectEmpresa(emp) {
      console.log("emp", emp);
      this.empresaSelecionada = this.empresas.find((x) => x.cod_empresa == emp);
      console.log("sel", this.empresaSelecionada);
      //   this.caixas = await Caixa.getAll(this.empresaSelecionada);
      this.loadData();
    },
    print() {
      PrinterWindowHelper(document.querySelector("#printDiv").innerHTML);
    },
    async loadData() {
      this.loading = true;
      try {
        this.lista = [];
        let res = await CaixaLib.getListagemCaixa({
          cod_empresa: this.empresaSelecionada.cod_empresa,
          cod_caixa: -1, //this.caixaSelecionado.cod_caixa,
          data: this.data,
        });
        if (res && res.success) {
          this.lista = res.data;
        }
      } catch (error) {
        console.log("error on loadData", error);
      }
      this.loading = false;
    },
    async carregaCaixas() {
      this.$forceUpdate();
      await this.$nextTick();
      this.loadingCaixa = true;
      this.caixas = [];
      if (this.empresa) {
        console.log("carregaCaixas", this.empresa);
        this.caixas = await CaixaLib.getAll(this.empresaSelecionada);
      }
      this.loadingCaixa = false;
    },
    async carregaEmpresas() {
      this.loadingEmpresa = true;
      this.empresas = await EmpresasLib.getResumo();
      this.loadingEmpresa = false;
    },
    async openPedido(pedido) {
      if (pedido && pedido.length > 0) {
        pedido = pedido[0];
        console.log("pedido", pedido);
        this.$refs.tbLista.clearSelected();

        if (pedido.tipo == "Mesa") {
          let result = await Mesalib.getMesaControle(pedido.cod_complemento);
          console.log("result", result);
          if (result && result.length > 0) {
            this.mesaSelected = result[0];
            this.$bvModal.show("modal-open-comanda");
          } else {
            this.$bvToast.toast("Mesa não encontrada", {
              title: "Erro",
              variant: "danger",
              solid: true,
            });
          }
        } else {
          let result = await PedidoLib.get({
            cod_pedido: pedido.cod_complemento,
          });
          console.log("result pedido", result);
          if (result && result.success) {
            this.pedidoSelected = result.data[0];
            this.$bvModal.show("modal-open-pedido");
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
